<template>
  <div class="page-content">
    <Dialog
      type="error"
      :isShow="message !== ''"
      @close-dialog="message = ''"
      :message="message"
    >
    </Dialog>
    <div class="container">
      <h1>Checkout</h1>
      <section class="checkout-section pl-2 pr-2" v-if="cart && cart.length">
        <form>
          <!--div class="payments">
            <div class="row">
              <div class="col-2 text-right">
                <a href="#" class="btn btn-outline">
                  <img src="@/assets/images/img28.svg" alt="">
                  Card
                </a>
              </div>
              <div class="col-2">
                <a href="#" class="btn btn-outline">
                  <img src="@/assets/images/img29.svg" alt="">
                  Pay
                </a>
              </div>
            </div>
          </div-->
          <div class="checkout-data mb-2">
            <div class="row">
              <div class="col">
                <input id="name" type="text" placeholder="Name*" v-model="name">
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input id="email" type="email" placeholder="Email*" v-model="email">
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div id="sq-card-number"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <div id="sq-expiration-date"></div>
              </div>
              <div class="col-3">
                <div id="sq-cvv"></div>
              </div>
              <div class="col-3">
                <div class="third" id="sq-postal-code"></div>
              </div>
            </div>
          </div>
          <div class="tip">
            <h2 class="uppercase mb-1">Would you like to leave a tip?</h2>
            <div class="row">
              <div class="col-custom">
                <input @input="handleTipRadio($event)" type="radio"
                id="tip1" value="15" v-model="tips">
                <label for="tip1">15%</label>
              </div>
              <div class="col-custom">
                <input @input="handleTipRadio($event)" type="radio"
                id="tip2" value="25" v-model="tips">
                <label for="tip2">25%</label>
              </div>
              <div class="col-custom flex-none">
                <input
                  :class="{'active': custom}"
                  @input="handleTips"
                  placeholder="Custom"
                  type="number"
                  v-model="custom"
                  min="0"
                >
              </div>
            </div>
          </div>
          <div class="order-total">
            <hr class="divider-gray">
            <div class="row">
              <div class="col-2">
                <strong>Total</strong>
              </div>
              <div class="col-2 text-right">
                <strong>${{ renderCheckoutPrice }}</strong>
              </div>
            </div>
          </div>
          <div class="controls">
            <button
              class="btn"
              @click="requestCardNonce($event)"
              v-if="!isProcessingPayment"
              v-bind:disabled="isClosed"
            >
              Pay
            </button>
            <div class="btn" v-if="isProcessingPayment">
              <img
                :src="require('@/assets/images/loader-horizontal.svg')">
            </div>
          </div>
        </form>
      </section>
      <section v-else>
        <p class="text-center"><b>Cart is empty</b></p>
        <div class="controls">
          <Router-link
            class="btn btn-outline mb-1"
            :to="$options.links.home"
          >
            Would you like to add more?
          </Router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ROUTES from '@/constans/route';
import moment from 'moment';
import generateApiUrl from '@/utility/helper';

export default {
  name: 'Checkout',
  links: {
    home: ROUTES.HOME,
  },
  data() {
    return {
      tips: null,
      custom: null,
      totalPrice: this.$store.state.db.totalPrice,
      checkoutPrice: this.$store.state.db.totalPrice,
      message: '',
      name: '',
      email: '',
      tip: 0,
      isProcessingPayment: false,
      locationId: process.env.VUE_APP_LOCATION_ID,
      applicationId: process.env.VUE_APP_SQ_APP_ID,
    };
  },
  mounted() {
    const self = this;
    // eslint-disable-next-line no-undef
    this.paymentForm = new SqPaymentForm({
      autoBuild: true,
      applicationId: self.applicationId,
      locationId: self.locationId,
      inputClass: 'sq-input',
      // Initialize the payment form elements

      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [
        {
          fontFamily: 'Arial',
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: '400',
        },
      ],

      // Initialize the credit card placeholders
      cardNumber: {
        elementId: 'sq-card-number',
        placeholder: 'Card number',
      },
      cvv: {
        elementId: 'sq-cvv',
        placeholder: 'CVV',
      },
      expirationDate: {
        elementId: 'sq-expiration-date',
        placeholder: 'MM / YY',
      },
      postalCode: {
        elementId: 'sq-postal-code',
        placeholder: 'Zip Code',
      },

      // SqPaymentForm callback functions
      callbacks: {
        /*
           * callback function: methodsSupported
           * Triggered when: the page is loaded.
           */
        methodsSupported: (methods) => {
          // Only show the button if Apple Pay for Web is enabled
          // Otherwise, display the wallet not enabled message.
          self.applePay = methods.applePay;
          self.masterpass = methods.masterpass;
        },

        /*
           * Digital Wallet related functions
           */
        createPaymentRequest: () => {
          let paymentRequestJson;
          /* ADD CODE TO SET/CREATE paymentRequestJson */
          return paymentRequestJson;
        },
        // eslint-disable-next-line no-unused-vars
        validateShippingContact: (contact) => {
          let validationErrorObj;
          /* ADD CODE TO SET validationErrorObj IF ERRORS ARE FOUND */
          return validationErrorObj;
        },

        /*
           * callback function: cardNonceResponseReceived
           * Triggered when: SqPaymentForm completes a card nonce request
           */
        // eslint-disable-next-line no-unused-vars
        cardNonceResponseReceived: (errors, nonce, cardData) => {
          if (errors) {
            self.isProcessingPayment = false;
            errors.forEach((error) => {
              self.message += `${error.message}<br>`;
            });
            return;
          }

          const lineItems = [];
          self.cart.forEach((item) => {
            const lineItem = {
              cid: item.catalogId,
              vid: item.variantId,
              price: item.price,
              flavors: item.flavors,
            };
            if (item.typeOfBuild === 'scoop') {
              lineItem.toppings = item.toppings;
            } else if (item.typeOfBuild === 'milkshake') {
              [lineItem.milk] = item.milk;
            } else if (item.typeOfBuild === 'soda') {
              [lineItem.soda] = item.soda;
            }
            lineItems.push(lineItem);
          });

          axios.post(generateApiUrl('order/new'), {
            name: self.name,
            email: self.email,
            items: lineItems,
            nonce,
            total: self.totalPrice,
            tip: self.tip,
            pickUpDate: self.pickUpDateTime,
          })
            .then((result) => {
              const resp = result.data;
              if (resp.success) {
                self.trackPixel('Purchase');
                self.$store.commit('clearState');
                self.$router.push({ name: 'ThankYou' });
              } else {
                self.isProcessingPayment = false;
                self.message = resp.message;
                resp.errors.forEach((error) => {
                  self.message += `<br>${error}`;
                });
              }
            })
            .catch(() => {
              self.isProcessingPayment = false;
              self.message = 'Oops, there was an issue processing your order. Please try again. '
                  + 'If the problem persists please <a href="tel:760-507-4005">call us</a> '
                  + 'or <a href="mailto:info@icecreamandshop.com">email us</a>.';
            });
        },
      },
    });
    if (this.cart && this.cart.length) {
      this.paymentForm.build();
      this.trackPixel('InitiateCheckout');
    }
    if (this.isClosed) {
      this.message = 'We are currently closed. Our hours are 12pm to 9pm, everyday.';
    }
  },
  computed: {
    ...mapGetters(['cart', 'pickUpDateTime']),
    renderCheckoutPrice() {
      return this.checkoutPrice.toFixed(2);
    },
    isClosed() {
      const open = moment().hour(12).minute(0);
      const close = moment().hour(21).minute(0);
      const current = moment();
      return (current < open || current > close);
    },
  },
  methods: {
    handleTips({ target: { value } }) {
      if (value !== '') {
        this.tips = null;
        this.tip = +value;
        this.checkoutPrice = +this.totalPrice + +value;
      } else {
        this.tip = 0;
        this.checkoutPrice = +this.totalPrice;
      }
    },
    handleTipRadio({ target: { value } }) {
      this.custom = null;
      this.tip = +this.totalPrice * (+value / 100);
      this.checkoutPrice = +this.totalPrice + this.tip;
    },
    requestCardNonce(event) {
      event.preventDefault();
      let processPayment = true;
      this.message = '';
      if (this.name === '' || this.email === '') {
        processPayment = false;
        this.message = 'Please fill out required fields*.<br>';
      }
      if (!this.validateEmail()) {
        processPayment = false;
        this.message += 'Invalid email.';
      }

      if (processPayment) {
        this.isProcessingPayment = true;
        this.paymentForm.requestCardNonce();
      }
    },
    validateEmail() {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email);
    },
    trackPixel(eventName) {
      const contents = [];
      const contentIds = [];
      let count = 0;
      this.cart.forEach((item) => {
        count += 1;

        if (!contentIds.includes(item.variantId)) {
          contentIds.push(item.variantId);
        }

        let found = false;
        contents.forEach((content) => {
          if (content.id === item.variantId) {
            found = true;
            // eslint-disable-next-line no-param-reassign
            content.quantity += 1;
            // eslint-disable-next-line no-param-reassign
            content.value += item.price;
          }
        });

        if (!found) {
          contents.push({
            id: item.variantId,
            quantity: 1,
            name: item.title,
            value: item.price,
          });
        }
      });

      if (process.env.NODE_ENV === 'production') {
        // eslint-disable-next-line no-undef
        fbq('track', eventName, {
          content_name: 'Checkout',
          content_category: 'Ice Cream',
          content_ids: contentIds,
          contents,
          content_type: 'product',
          num_items: count,
          value: this.checkoutPrice.toFixed(2),
          currency: 'USD',
        });
      }
    },
  },
};
</script>

<style scoped>
  .order-total {
    margin-bottom: 30px;
  }
</style>
